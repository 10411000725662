import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'

export default class Level extends React.Component {
  render() {
    const level = get(this, 'props.data.contentfulResource')
    const metaDescription = `Everything you need to teach ${level.title} in the classroom. Materials included!`
    return (
      <Layout pageTitle={level.title} description={metaDescription}>
        <h1>{level.title}</h1>
        <hr />
        {level.description && (
          <div
            dangerouslySetInnerHTML={{
              __html: level.description.childMarkdownRemark.html,
            }}
          />
        )}
        <hr />
        <Link to="/resources/levels/" className="btn btn-outline-primary">
          Back to all levels
        </Link>
      </Layout>
    )
  }
}

export const Query = graphql`
  query LevelQuery($slug: String!) {
    contentfulResource(slug: { eq: $slug }) {
      description {
        childMarkdownRemark {
          html
        }
      }
      title
    }
  }
`
